import React from "react";
import { Check } from "app/components/Icon";
import { AccessType, Plan } from "services/graphql";
import { useUserHasTrialed } from "hooks/Subscriptions/useUserHasTrialed";
import styles from "./styles.module.scss";

const ACCESS_BENEFITS_MAP: { [key in AccessType]: any } = {
  [AccessType.Free]: [<>Limited-access Dance Library</>, <>Free Classes</>],
  [AccessType.Basic]: [
    <>Limited-access Dance Library</>,
    <>Basic Programs and Classes</>,
  ],
  [AccessType.Standard]: [
    <>Full-access Dance Library</>,
    <>Choreography with Top Instructors</>,
  ],
  [AccessType.Premium]: [
    <>Full-access Dance Library</>,
    <>Choreography with Top Instructors</>,
  ],
};

interface Props {
  plan: Plan;
}

export function BenefitsText({ plan }: Props) {
  const { hasTrialed } = useUserHasTrialed();
  const { canTrial, trialPeriod, trialPeriodUnit } = plan;

  const TRIAL_BENEFITS = [
    <>
      {trialPeriod} {trialPeriodUnit} Free Trial
    </>,
    ...ACCESS_BENEFITS_MAP[plan.accessType],
  ];

  const NON_TRIAL_BENEFITS = ACCESS_BENEFITS_MAP[plan.accessType];

  const benefits =
    canTrial && !hasTrialed ? TRIAL_BENEFITS : NON_TRIAL_BENEFITS;

  return (
    <div className={styles.benefitsContainer}>
      {benefits.map((benefit: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={styles.textContainer}>
          <Check className={styles.icon} />
          <p>{benefit}</p>
        </div>
      ))}
    </div>
  );
}
