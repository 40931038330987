import { useGetUserHasTrialedQuery } from "services/graphql";

export function useUserHasTrialed() {
  const { data, loading, error } = useGetUserHasTrialedQuery();

  if (loading) {
    return {};
  }

  if (!data) {
    return {};
  }

  // @TODO?: Throw some kind of error
  if (error) {
    return {};
  }
  const { me } = data;

  if (!me) {
    return {};
  }

  const { hasTrialed } = me;

  return {
    hasTrialed,
  };
}
